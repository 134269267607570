
import { defineComponent } from "vue";
import { useStore } from "vuex";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import DotsVerticalIcon from "icons/DotsVertical.vue";
import PostDetail from "@/components/common/post/PostDetail.vue";
import CommentList from "@/components/comment/List.vue";
import CommentItem from "@/components/comment/Item.vue";
import WriteComment from "@/components/comment/WriteComment.vue";
import type { Post } from "@/components/common/post/post";
import type { Comment } from "@/components/comment/comment";
import LoadingIcon from "icons/Loading.vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default defineComponent({
  name: "UrgentDetail",
  setup: () => {
    return;
  },
  components: {
    BaseHeader2,
    DotsVerticalIcon,
    PostDetail,
    CommentList,
    CommentItem,
    WriteComment,
    LoadingIcon,
    InfiniteLoading,
  },
  data() {
    const store = useStore();
    return {
      store,
      postId: null as null | string,
      post: {} as Post,
      commentList: [] as Array<Comment>,
      infiniteId: +new Date(),
      page: 1,
      innerComment: {} as {
        [key: string]: {
          page: number;
          list: Array<Comment>;
        };
      },
      newComment: "",
      isSecret: false,
      mentionedNickname: "",
      replyTarget: null as null | { id: number; mention: string },
    };
  },
  computed: {
    member: function () {
      return this.store.getters["member/member"];
    },
    isLogin: function () {
      return this.store.getters["member/isLogin"];
    },
  },
  methods: {
    infiniteHandler($state: any) {
      this.load()
        .then((res: any) => {
          $state.loaded();
          if (this.commentList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.loaded();
          $state.complete();
        });
    },
    load() {
      return this.$axios
        .get(`/urgent/${this.postId}/comment`, {
          params: {
            page: this.page,
          },
        })
        .then((result) => {
          let res = result.data.results;
          if (res.length > 0) {
            if (this.page == 1) {
              this.commentList.splice(0, this.commentList.length);
              this.commentList.push(...res);
            } else {
              this.commentList.push(...res);
            }
          }
          return result;
        });
    },
    nextPageInnerCommentList(key: string) {
      if (this.innerComment[key]) {
        return this.innerComment[key].page++;
      }
      return 1;
    },
    lenghtInnerCommentList(key: string) {
      return this.innerComment[key]?.list.length ?? 1;
    },
    hasInnerCommentList(key: string) {
      return key in this.innerComment;
    },
    appendInnerCommentList(key: string) {
      return (this.innerComment[key] = { page: 1, list: [] } as {
        page: number;
        list: Array<Comment>;
      });
    },
    getInnerCommentList(key: string) {
      if (this.hasInnerCommentList(key)) {
        return this.innerComment[key].list;
      }
      return this.appendInnerCommentList(key).list;
    },
    pushInnerCommentList(key: string, item: Array<Comment> | Comment) {
      if (Array.isArray(item)) {
        this.innerComment[key].list.push(...item);
        return;
      }
      this.innerComment[key].list.push(item);
    },
    loadInnerComment(key: string, parent_id: number, page: number | null) {
      this.$axios
        .get(`/urgent/${this.postId}/comment`, {
          params: {
            page: page ?? 1,
            parent_id: parent_id,
          },
        })
        .then((result) => {
          let res = result.data.results;
          if (this.hasInnerCommentList(key)) {
            this.pushInnerCommentList(key, res);
          } else {
            this.appendInnerCommentList(key);
            this.pushInnerCommentList(key, res);
          }
          this.clearReplyTarget();
        });
      this.newComment = "";
    },
    showPostMenu(postId: number, isAuthor: boolean) {
      let menu = [];
      if (isAuthor) {
        menu.push(
          ...[
            {
              display: "수정하기",
              action: "updatePost",
              parameter: `${postId}`,
              color: "ok",
            },
            {
              display: "삭제",
              action: "deletePostAlert",
              parameter: `${postId},'Urgent'`,
              color: "nok",
            },
          ]
        );
      } else {
        menu.push(
          ...[
            {
              display: "차단하기",
              action: "showBlockUrgentAlert",
              color: "ok",
            },
            {
              display: "신고",
              action: "report",
              parameter: `${postId},'Urgent'`,
              color: "nok",
            },
          ]
        );
      }

      this.$flutter.callHandler("bottomAlert", {
        actions: JSON.stringify(menu),
      });
    },
    showCommentMenu(
      commentId: number,
      status: string,
      isAuthor: boolean,
      commentIndex: string | number
    ) {
      let menu = [];
      if (isAuthor) {
        menu.push(
          ...[
            {
              display: "삭제",
              action: "showCommentDeleteAlert",
              parameter: `${commentId},'${commentIndex}'`,
              color: "nok",
            },
          ]
        );

        if (status == "공개") {
          menu.unshift({
            display: "비밀 댓글로 전환",
            action: "updateCommentStatus",
            parameter: `${commentId},'비공개','${commentIndex}'`,
            color: "ok",
          });
        } else {
          menu.unshift({
            display: "공개 댓글로 전환",
            action: "updateCommentStatus",
            parameter: `${commentId},'공개','${commentIndex}'`,
            color: "ok",
          });
        }
      } else {
        menu.push(
          ...[
            {
              display: "신고",
              action: "report",
              parameter: `${commentId},'UrgentComment'`,
              color: "nok",
            },
          ]
        );
      }

      this.$flutter.callHandler("bottomAlert", {
        actions: JSON.stringify(menu),
      });
    },
    showCommentDeleteAlert(commentId: number, commentIndex: string) {
      this.$flutter.callHandler("alert", {
        title: "삭제하기를 누르시면 복구가 불가합니다.\n삭제하시겠습니까?",
        actions: JSON.stringify([
          {
            display: "삭제하기",
            action: "updateCommentStatus",
            parameter: `${commentId},'삭제','${commentIndex}'`,
          },
          {
            display: "닫기",
            action: "nok",
          },
        ]),
      });
    },
    updateCommentStatus(
      commentId: number,
      status: string,
      commentIndex: string
    ): void {
      if (status == "삭제") {
        this.$axios.delete(`/urgent/comment/${commentId}`).then(() => {
          if (commentIndex.includes("inner-comment")) {
            const idx = this.innerComment[commentIndex].list.findIndex(
              (x) => x.id === commentId
            );
            this.innerComment[commentIndex].list[idx].status = status;
          } else {
            this.commentList[Number(commentIndex)].status = status;
          }
        });
      } else {
        if (status == "공개") {
          this.$pushGAEvent("reply_to_public", {
            type: "urgent",
            announcement_category:
              this.post.category === "배우" ? "actor" : "staff",
          });
        } else if (status == "비공개") {
          this.$pushGAEvent("reply_to_secret", {
            type: "urgent",
            announcement_category:
              this.post.category === "배우" ? "actor" : "staff",
          });
        }
        this.$axios
          .patch(`/urgent/comment/${commentId}`, {
            status: status,
          })
          .then(() => {
            if (commentIndex.includes("inner-comment")) {
              const idx = this.innerComment[commentIndex].list.findIndex(
                (x) => x.id === commentId
              );
              this.innerComment[commentIndex].list[idx].status = status;
            } else {
              this.commentList[Number(commentIndex)].status = status;
            }
          });
      }
    },
    blockUrgent() {
      this.$axios.post("/urgent/block", { target: this.postId }).then(() => {
        this.$router.go(-1);
      });
    },
    showBlockUrgentAlert() {
      if (!this.isLogin) {
        this.$flutter.callHandler("showToast", {
          message: "로그인 사용자만 차단할 수 있습니다.",
        });
        this.$router.push("/login/before?redirect=/urgent/" + this.postId);
        return;
      }
      this.$flutter.callHandler("alert", {
        title: "차단하기를 누르면 글이\n가려집니다. 차단하시겠습니까?",
        message: "차단 후에는 해제할 수 없으니 신중히 사용해 주세요.",
        actions: JSON.stringify([
          {
            display: "차단하기",
            action: "blockUrgent",
            parameter: "",
            color: "ok",
          },
          {
            display: "닫기",
            action: "nok",
          },
        ]),
      });
    },
    report(contentId: number, contentType: string) {
      if (!this.isLogin) {
        this.$flutter.callHandler("showToast", {
          message: "로그인 사용자만 신고할 수 있습니다.",
        });
      }
      this.$router.push({
        path: "/report",
        query: { contentId, contentType },
      });
    },
    selectReplyTarget(reply: { id: number; mention: string }) {
      if (this.isLogin) {
        this.replyTarget = reply;
        this.mentionedNickname = reply.mention;
      } else {
        this.$flutter.callHandler("showToast", {
          message: "로그인 사용자만 작성할 수 있습니다.",
        });
      }
    },
    clearReplyTarget() {
      this.replyTarget = { id: -1, mention: "" };
      this.mentionedNickname = "";
    },
    submit() {
      let params = {
        urgent: this.postId,
        content: this.newComment,
      } as {
        urgent: string;
        content: string;
        parent?: number;
        status?: string;
      };

      if (this.replyTarget && this.replyTarget.id > 0) {
        params["parent"] = this.replyTarget.id;
        params["content"] =
          `@${this.replyTarget.mention}` + " " + params["content"];
      }

      if (this.isSecret) {
        params["status"] = "비공개";
      }

      this.$axios
        .post(`/urgent/${this.postId}/comment`, params)
        .then((result) => {
          this.$pushGAEvent("reply_complete", {
            type: "urgent",
            reply_secret: this.isSecret ? "true" : "false",
            announcement_category:
              this.post.category === "배우" ? "actor" : "staff",
          });
          this.newComment = "";
          this.isSecret = false;

          let today = new Date().toISOString();
          let createdComment = {} as Comment;

          createdComment.id = result.data.id;
          createdComment.member = {
            id: this.member?.id,
            thumbnail:
              this.member?.thumbnail ??
              require("@/assets/images/account_default.png"),
            nickname: this.member.nickname,
          };
          createdComment.status = result.data.status;
          createdComment.tstamp = today;
          createdComment.content = result.data.content;
          createdComment.child_count = 0;

          if (this.replyTarget && this.replyTarget.id > 0) {
            let index = this.commentList.findIndex((x) => {
              return x.id === result.data.parent;
            });
            this.commentList[index].child_count += 1;

            let key = "inner-comment" + result.data.parent;
            createdComment.parent = result.data.parent;
            if (this.hasInnerCommentList(key)) {
              this.pushInnerCommentList(key, createdComment);
            } else {
              this.appendInnerCommentList(key);
              this.pushInnerCommentList(key, createdComment);
            }
            this.clearReplyTarget();
          } else {
            this.commentList.push(createdComment);
          }
        });
    },
    deletePostAlert(postId: number) {
      this.$flutter.callHandler("alert", {
        title: "삭제하기를 누르시면 복구가 불가합니다.\n삭제하시겠습니까?",
        message: "글, 이력, 댓글, 대댓글 모두 삭제됩니다.",
        actions: JSON.stringify([
          {
            display: "삭제하기",
            action: "deletePost",
            parameter: `${postId}`,
          },
          {
            display: "닫기",
            action: "nok",
          },
        ]),
      });
    },
    deletePost(postId: number) {
      this.$flutter.callHandler("showToast", {
        message: "삭제되었습니다.",
        color: "error",
      });
      this.$axios.delete(`/urgent/${postId}`).then(() => {
        this.$router.replace("/urgent");
      });
    },
    updatePost(postId: number) {
      this.$router.push(`/urgent/${postId}/update`);
    },
  },
  mounted() {
    this.postId = this.$route.params.id.toString();
    if (this.postId) {
      this.$axios.get(`/urgent/${this.postId}`).then((result) => {
        Object.assign(this.post, result.data);
        this.$pushGAEvent("urgent_view", {
          announcement_category:
            this.post.category === "배우" ? "actor" : "staff",
        });
      });
    }
  },
  beforeMount(): void {
    window.deletePostAlert = this.deletePostAlert;
    window.deletePost = this.deletePost;
    window.updatePost = this.updatePost;
    window.updateCommentStatus = this.updateCommentStatus;
    window.showCommentDeleteAlert = this.showCommentDeleteAlert;
    window.report = this.report;

    window.blockUrgent = this.blockUrgent;
    window.showBlockUrgentAlert = this.showBlockUrgentAlert;
  },
  beforeUnmount(): void {
    window.deletePostAlert = null;
    window.deletePost = null;
    window.updatePost = null;
    window.updateCommentStatus = null;
    window.showCommentDeleteAlert = null;
    window.report = null;

    window.blockUrgent = null;
    window.showBlockUrgentAlert = null;
  },
});
